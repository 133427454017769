import logo from './logo.svg';
import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './pages/Home';
import HoursAndLocations from './pages/HoursAndLocations';
import Breakfast from './pages/Breakfast';
import Contact from './pages/Contact';
import About from './pages/About';
import Lunch from './pages/Lunch';
import Dinner from './pages/Dinner';
import Bakery from './pages/Bakery';
import './pages/custom.css'

function App() {
  return (
    <>
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path='/hours_locations' element={<HoursAndLocations />} />
          <Route path='/breakfast' element={<Breakfast />} />
          <Route path='/lunch' element={<Lunch />} />
          <Route path='/dinner' element={<Dinner />} />
          <Route path='/bakery' element={<Bakery />} />
          <Route path='/contact' element={<Contact />} />
          <Route path='/about' element={<About />} />
        </Routes>
      </Router>
    </>
  );
}

export default App;

import { database as db } from "../config/firebase";
import { ref, onValue } from "firebase/database";

const breakfastMenuRef = ref(db, 'breakfastItems/'); //Ref to the breakfastItems node
const lunchMenuRef = ref(db, 'lunchItems/');
const dinnerMenuRef = ref(db, 'dinnerItems/');
const carouselHomeRef = ref(db, 'carouselHome/');
const carouselAboutRef = ref(db, 'carouselAbout/');
const carouselBakeryRef = ref(db, 'carouselBakery/');
const teamMembersRef = ref(db, 'teamMembers/');
const contactRef = ref(db, 'contact/');

const getBreakfastMenu = async () => {
    return new Promise((resolve, reject) => {
      onValue(breakfastMenuRef, (snapshot) => {
        const data = snapshot.val();
        if (data) {
          // Convert the data into an array of users with their IDs
          const breakfastItems = Object.keys(data).map((itemId) => ({
            id: itemId, // Include the user's ID
            ...data[itemId], // Include the user's data
          }));
          resolve(breakfastItems);
        } else {
          reject('No data available');
        }
      });
    });
  };
  
  const getLunchMenu = async () => {
    return new Promise((resolve, reject) => {
      onValue(lunchMenuRef, (snapshot) => {
        const data = snapshot.val();
        if (data) {
          // Convert the data into an array of users with their IDs
          const lunchItems = Object.keys(data).map((itemId) => ({
            id: itemId, // Include the user's ID
            ...data[itemId], // Include the user's data
          }));
          resolve(lunchItems);
        } else {
          reject('No data available');
        }
      });
    });
  };
  
  const getDinnerMenu = async () => {
    return new Promise((resolve, reject) => {
      onValue(dinnerMenuRef, (snapshot) => {
        const data = snapshot.val();
        if (data) {
          // Convert the data into an array of users with their IDs
          const dinnerItems = Object.keys(data).map((itemId) => ({
            id: itemId, // Include the user's ID
            ...data[itemId], // Include the user's data
          }));
          resolve(dinnerItems);
        } else {
          reject('No data available');
        }
      });
    });
  };

  const getHomeImages = async () => {
    return new Promise((resolve, reject) => {
      onValue(carouselHomeRef, (snapshot) => {
        const data = snapshot.val();
        if (data) {
          // Convert the data into an array of users with their IDs
          const homeImages = Object.keys(data).map((imageId) => ({
            id: imageId, 
            ...data[imageId], 
          }));
          resolve(homeImages);
        } else {
          reject('No data available');
        }
      });
    });
  };
  
  const getAboutImages = async () => {
    return new Promise((resolve, reject) => {
      onValue(carouselAboutRef, (snapshot) => {
        const data = snapshot.val();
        if (data) {
          // Convert the data into an array of users with their IDs
          const aboutImages = Object.keys(data).map((imageId) => ({
            id: imageId, 
            ...data[imageId], 
          }));
          resolve(aboutImages);
        } else {
          reject('No data available');
        }
      });
    });
  };
  
  const getBakeryImages = async () => {
    return new Promise((resolve, reject) => {
      onValue(carouselBakeryRef, (snapshot) => {
        const data = snapshot.val();
        if (data) {
          // Convert the data into an array of users with their IDs
          const bakeryImages = Object.keys(data).map((imageId) => ({
            id: imageId, 
            ...data[imageId], 
          }));
          resolve(bakeryImages);
        } else {
          reject('No data available');
        }
      });
    });
  };

  const getTeamMembers = async () => {
    return new Promise((resolve, reject) => {
      onValue(teamMembersRef, (snapshot) => {
        const data = snapshot.val();
        if (data) {
          // Convert the data into an array of users with their IDs
          const teamMembers = Object.keys(data).map((memberId) => ({
            id: memberId, // Include the user's ID
            ...data[memberId], // Include the user's data
          }));
          resolve(teamMembers);
        } else {
          reject('No data available');
        }
      });
    });
  };

  const getContactDetails = async () => {
    return new Promise((resolve, reject) => {
      onValue(contactRef, (snapshot) => {
        const data = snapshot.val();
  
        if (data) {
          const { address, phoneNumber, facebook, email } = data;
  
          resolve({
            address: address || '',
            phoneNumber: phoneNumber || '',
            facebook: facebook || '',
            email: email || '',
          });
        } else {
          reject('No contact details available');
        }
      });
    });
  };


  export {getBreakfastMenu, getLunchMenu, getDinnerMenu, getHomeImages, getAboutImages, getBakeryImages,
           getTeamMembers, getContactDetails };
import React, { useState, useEffect } from "react";
import { Envelope, Facebook } from "react-bootstrap-icons";
import { Link } from "react-router-dom";
import { getContactDetails } from "../pages/GetData";
import "./custom.css";
import "./footer.css";

const Footer = (props) => {
  const [contactDetails, setContactDetails] = useState({});
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        try {
            const contactDetailsData = await getContactDetails();
            setContactDetails(contactDetailsData);
            setLoading(false);
        } catch (error) {
            console.error(error);
            setLoading(false);
        }
    };

    const facebook = contactDetails.facebook;
    const email = contactDetails.email;

  return (
    <footer className="custom-footer">
      <div className="container">
        {/* Content for medium and large screens */}
        <div className="d-none d-md-flex justify-content-between align-items-center py-3">
          <div>
            <a href={`mailto:${email}`} className="footer-link" target="_blank" rel="noopener noreferrer">
              <Envelope size={20} />
            </a>
            <a href={facebook} className="footer-link" target="_blank" rel="noopener noreferrer">
              <Facebook size={20} />
            </a>
          </div>
          <Link to="/contact" className="link-contact"><span className={props.activePath === "/contact" ? "active" : ""}>CONTACT</span></Link>
        </div>

        {/* Content for small screens */}
        <div>
            <div className="d-md-none d-flex justify-content-center align-items-center">
                <a href="tel:+61-449-993-531" className="footer-link-mobile">
                    Call us +61-449-993-531
                </a>
                </div>
                <div className="d-md-none text-center ">
                    <hr className="hr-footer" />
                    <Link to="#reserve" className="footer-link-mobile">RESERVATIONS</Link>
                </div>
            </div>
        </div>
        
    </footer>
  );
};

export default Footer;
import React, { useState, useEffect} from "react";
import { Container, Form } from 'react-bootstrap';
import Navigation from '../components/Navigation';
import Header from '../components/Header';
import HeaderMob from "../components/HeaderMob";
import Footer from "../components/Footer";
import ImageCarousel from "../components/ImageCarousel";
import { getAboutImages, getTeamMembers } from "./GetData";
import "./custom.css";
import "./about.css";

const About = () => {
    const [carouselImages, setCarouselImages] = useState([]);
    const [teamMembers, setTeamMembers] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchHomeImages = async () => {
            try {
                const images = await getAboutImages();

                const urls = images.map(image => image.imageUrl);
                setCarouselImages(urls);
            } catch (error) {
                console.error(error);
            }
        };

        fetchHomeImages();
    }, []);

    useEffect(() => {
        fetchData();
      }, []);
    
      const fetchData = async () => {
        try {
          const team = await getTeamMembers();
          setTeamMembers(team);
          setLoading(false);
        } catch (error) {
          console.error(error);
          setLoading(false);
        }
      };

    return (
        <Container fluid>
            <Header />
            <Navigation activePath="/about" />
            <HeaderMob />

            <ImageCarousel images={carouselImages} />

            <div className="main">
                <div className="about">
                    <div className="about-descrip">
                        <h1>WHERE SPICE IS KING</h1>
                        <p>Welcome to Spicy Kitchen, where spice reigns supreme. Our culinary artisans are masters of the 
                            spice palette, creating dishes that awaken your taste buds. We take you on a global spice trail, 
                            sourcing the finest ingredients for an unforgettable dining experience.</p>
                    </div>
                    <h2 className="about-h2">OUR TEAM</h2>
                    {loading ? null : (
                        <div className="team">
                            <div className="row justify-content-center">
                                {teamMembers.map((member) => (
                                <div key={member.id} className="col-sm-12 col-md-6 col-lg-4">
                                    <div className="image-group">
                                    <img src={member.imageUrl} alt={member.name} />
                                    <h5>{member.name}</h5>
                                    <h6>{member.company_role}</h6>
                                    </div>
                                </div>
                                ))}
                            </div>
                        </div>
                    )}
                </div>
            </div>

            <Footer />
      </Container>
    );
}

export default About;
import React, { useState, useEffect } from "react";
import { getContactDetails } from "../pages/GetData";
import "./custom.css"

function HeaderMob(props) {
    const [address, setAddress] = useState('');
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        try {
            const contactDetailsData = await getContactDetails();
            setAddress(contactDetailsData.address);
            setLoading(false);
        } catch (error) {
            console.error(error);
            setLoading(false);
        }
    };

    
    const googleMapsUrl = `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(address)}`;

    return (
        <>
            <div className="custom-bg-gold custom-text-green py-2 header-mobile">
                <div className="container">
                    <div className="d-flex justify-content-center align-items-center">
                        <div className="contact-info d-flex ">
                            <div className="address mr-3">
                                <a href={googleMapsUrl}
                                    target="_blank" rel="noopener noreferrer" className="link-as-text" >
                                    {address}
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
        </>
        
    );
}

export default HeaderMob;
import React from "react";
import { useState } from "react";
import { database } from "../config/firebase";
import { ref, set, push, serverTimestamp } from "firebase/database";
import { Container, Form } from 'react-bootstrap';
import Navigation from '../components/Navigation';
import Header from '../components/Header';
import HeaderMob from "../components/HeaderMob";
import Footer from "../components/Footer";
import "./custom.css";
import "./contact.css";

const Contact = () => {
    const [userName, setUserName] = useState('');
    const [email, setEmail] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [message, setMessage] = useState('');

    const handleInputChange = (e) => {
        const {name, value} = e.target;
        if (name === 'userName') {
            setUserName(value);
        }else if (name === 'email') {
            setEmail(value);
        }else if (name === 'phoneNumber') {
            setPhoneNumber(value);
        }else if (name === 'message') {
            setMessage(value);
        }
    }
    
    const handleSend = (e) => {
        e.preventDefault();

        const messageRef = ref(database, 'user_message/');
      
        // Additional user details
        const messageDetails = {
            userName: userName, 
            email : email,
            phoneNumber: phoneNumber,
            message: message,
            status: 'Pending',
            timestamp: serverTimestamp()   
        };

        const newMessageRef = push(messageRef); // This generates a new unique key for the message.
    
        // Store additional user details in the Realtime Database
        set(newMessageRef, messageDetails)
            .then(() => {
                alert('Message Sent Successfully');
                setUserName('');
                setEmail('');
                setPhoneNumber('');
                setMessage('');
            })
            .catch((error) => {
                alert('Unable to send the message');
                console.error('Error storing user details in the database: ', error);
            });
    }

    return (
        
        <Container fluid>    
            
            
            <Header />
            <Navigation activePath="/contact"/>
            <HeaderMob />
            
            <div className="main">
                <div className="contact">
                    <form className="contact-form" onSubmit={handleSend}>
                        <h1>CONTACT US</h1>
                        <div className="mb-3">
                            <label htmlFor="userName" className="form-label">Name</label>
                            <input type="text" className="form-control" id="nameContact" placeholder="Your Name" 
                               name="userName" value={userName} onChange={handleInputChange} required/>
                        </div>
                        <div className="mb-3">
                            <label htmlFor="email" className="form-label">Email</label>
                            <input type="email" className="form-control" id="emailContact" placeholder="name@example.com" 
                               name="email" value={email} onChange={handleInputChange} required/>
                        </div>
                        <div className="mb-3">
                            <label htmlFor="phoneNumber" className="form-label">Phone Number</label>
                            <input type="tel" className="form-control" id="phoneContact" placeholder="0-123-456-789" 
                               name="phoneNumber" value={phoneNumber} onChange={handleInputChange} />
                        </div>
                        <div className="mb-3">
                            <label htmlFor="message" className="form-label">Message</label>
                            <textarea className="form-control" id="messageContact" rows="3" placeholder="Your message here" 
                               name="message" value={message} onChange={handleInputChange} required></textarea>
                        </div>
                        <div className="btn-center">
                            <button type="submit" className="btn btn-send"><span className="">SEND</span></button>
                        </div>
                        
                    </form>
                </div>
            </div>
          
            <Footer activePath="/contact" />
      </Container>
    );
}

export default Contact;
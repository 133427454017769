import React, { useEffect, useState } from "react";
import { Container, Form } from 'react-bootstrap';
import Navigation from '../components/Navigation';
import Header from '../components/Header';
import HeaderMob from "../components/HeaderMob";
import Footer from "../components/Footer";
import "./custom.css";
import { getLunchMenu } from "./GetData";

const Lunch = () => {
    const [lunchItems, setLunchItems] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        try {
            const lunch = await getLunchMenu();
            setLunchItems(lunch);
            setLoading(false);
        } catch (error) {
            console.error(error);
            setLoading(false);
        }
    };

    return (
        <Container fluid>
            <Header />
            <Navigation activePath="/menu" />
            <HeaderMob />

            <div className="main">
                <h1>LUNCH</h1>
                <div className="meal">
                    
                {loading ? null :
                        <div className="menu">
                            <div className="row justify-content-center">
                                {lunchItems.map((item) => (
                                    <div key={item.id} className="col-sm-6 col-md-4 col-lg-3">
                                        <div className="image-group">
                                            <img src={item.imageUrl} alt={item.name} />
                                            <h5>{item.name}</h5>
                                            <h6>{item.price}</h6>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    }
                </div>
            </div>

            <Footer />
      </Container>
    );
}

export default Lunch;
import React, { useState } from "react";
import {
    Navbar,
    Nav
   } from "react-bootstrap";
import Dropdown_custom from "./Dropdown_custom";
import logo from '../images/logo.png';
import "./custom.css";
import "./navigation.css";


const Navigation = (props) => {
    const drop_items = ["Breakfast", "Lunch", "Dinner"];
    const drop_items_target = ["/breakfast", "/lunch", "/dinner"];
    const drop_classNames = {
                                title: "custom-navbar-text dropdown_title",
                                item: "custom-navbar-text dropdown_title dropdown_item"
    }
 
    return (
        <Navbar variant="dark" expand="lg" className="sticky-top custom-navbar-bg">
            <Navbar.Brand href="/">
                <img src={logo} width="70px" height="70px" className="d-inline-block align-center" alt="logo.jpg" />
                <span className="custom-brand">Spicy Kitchen</span> 
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ml-auto">
                
                <Nav.Link href="/hours_locations" className={props.activePath === "/hours_locations" ? "active" : ""}>
                    <span className="custom-navbar-text">HOURS & LOCATIONS</span>
                </Nav.Link>
                <Dropdown_custom drop_title="MENUS" drop_items={drop_items} drop_items_target={drop_items_target} 
                    classNames={drop_classNames} activePath={props.activePath === "/menu" ? "active" : ""}/>
                <Nav.Link href="/bakery" className={props.activePath === "/bakery" ? "active" : ""}>
                    <span className="custom-navbar-text">BAKERY</span>
                </Nav.Link>
                <Nav.Link href="/about" className={props.activePath === "/about" ? "active" : ""}>
                    <span className="custom-navbar-text">ABOUT</span>
                </Nav.Link>
                {/*<Nav.Link href="#events" className={props.activePath === "/events" ? "active" : ""}>
                    <span className="custom-navbar-text">EVENTS</span>
                </Nav.Link>*/}
                <Nav.Link href="/contact" className={`d-lg-none ${props.activePath === "/contact" ? "active" : ""}`}>
                    <span className="custom-navbar-text">CONTACT</span>
                </Nav.Link>
                {/*<Nav.Link href="#reserve" className={props.activePath === "/reserve" ? "active" : ""}>
                    <span className="custom-navbar-text bold">RESERVATIONS</span>
                </Nav.Link>*/}
            </Nav>
            </Navbar.Collapse>
        </Navbar>
    );
};

   export default Navigation;
import React, { useState, useEffect } from "react";

import { Container, Form } from 'react-bootstrap';
import Navigation from '../components/Navigation';
import Header from '../components/Header';
import HeaderMob from "../components/HeaderMob";
import Footer from "../components/Footer";
import ImageCarousel from "../components/ImageCarousel";
import { getHomeImages } from "./GetData";
import restaurant1 from "../images/restaurant1.jpg";
import restaurant2 from "../images/restaurant2.jpg";
import restaurant3 from "../images/restaurant3.jpg";
import "./custom.css";

const Home = () => {
    const [carouselImages, setCarouselImages] = useState([]);

    useEffect(() => {
        const fetchHomeImages = async () => {
            try {
                const images = await getHomeImages();

                const urls = images.map(image => image.imageUrl);
                setCarouselImages(urls);
            } catch (error) {
                console.error(error);
            }
        };

        fetchHomeImages();
    }, []);

    return (
        
        <Container fluid>    
            
            
            <Header />
            <Navigation activePath= "/" />
            <HeaderMob />
            
            <ImageCarousel images={carouselImages} />
            <div className="main">
                <div className="row des descrip des1">
                    <div className="col-sm-12 col-md-8">
                        <img src={restaurant1} />
                    </div>
                    <div className="col-sm-12 col-md-4">
                        <h1><span className="h1-title">DINING ROOM</span></h1>
                        <p className="p-des">
                            Welcome to a dining experience that transcends the ordinary. At SPICY KITCHEN, 
                            we have mastered the art of turning ingredients into poetry, 
                            and every dish we serve is a sonnet for your senses.
                            <div className="line mx-auto"></div>
                        </p>
                    </div>
                </div>

                <div className="row des descrip des2">
                    <div className="col-sm-12 col-md-8 order-md-2">
                        <img src={restaurant2} />
                    </div>
                    <div className="col-sm-12 col-md-4 order-md-1">
                    <h1><span className="h1-title">FRIENDLY SERVICE</span></h1>
                        <p className="p-des">
                            From the moment you walk through our doors, you'll be greeted with genuine smiles 
                            and a warm embrace. Our dedicated team takes pride in creating a welcoming atmosphere where 
                            you'll feel like part of our extended family. 
                            <div className="line mx-auto"></div>
                        </p>
                    </div>
                </div>
                
                <div className="row des descrip des3">
                    <div className="col-sm-12 col-md-8">
                        <img src={restaurant3} />
                    </div>
                    <div className="col-sm-12 col-md-4">
                        <h1><span className="h1-title">DELIOUS FOOD</span></h1>
                        <p className="p-des">
                            Step into a world of culinary delights, where our chefs craft each dish with 
                            passion and precision. Our menu is a journey that takes you to the heart of diverse cuisines, 
                            from the rich and aromatic spices of the East to the robust and comforting flavors of the West.
                            <div className="line mx-auto"></div>
                        </p>
                    </div>
                </div>
               
            </div>
            
            <Footer />
      </Container>
    );
}

export default Home;
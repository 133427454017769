import React, { useState, useEffect } from "react";
import { getContactDetails } from "../pages/GetData";
import "./custom.css";
import "./header.css";

function Header(props) {
    const [contactDetails, setContactDetails] = useState({});
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        try {
            const contactDetailsData = await getContactDetails();
            setContactDetails(contactDetailsData);
            setLoading(false);
        } catch (error) {
            console.error(error);
            setLoading(false);
        }
    };

    const phoneNumber = contactDetails.phoneNumber;
    const address = contactDetails.address;
    const googleMapsUrl = `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(address)}`;

    // Function to initiate a phone call when clicking the phone number
    const handlePhoneClick = () => {
        window.location.href = `tel:${phoneNumber}`;
    };

    return (
        <>
            <div className="custom-bg-gold custom-text-green py-2 header-laptop">
                <div className="container">
                    <div className="d-flex justify-content-center align-items-center">
                        <div className="contact-info d-flex ">
                            <div className="address mr-3">
                                <a href={googleMapsUrl}
                                    target="_blank" rel="noopener noreferrer" className="link-as-text" >
                                    {address}
                                </a>
                            </div>
                            <div className="phone mr-3">
                                <span onClick={handlePhoneClick}>{phoneNumber}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
        </>
        
    );
}

export default Header;
import React, {useState, useEffect } from "react";
import { Container, Form } from 'react-bootstrap';
import Navigation from '../components/Navigation';
import Header from '../components/Header';
import HeaderMob from "../components/HeaderMob";
import Footer from "../components/Footer";
import { getContactDetails } from "./GetData";
import "./custom.css";
import "./hoursandlocations.css";

const HoursAndLocations = () => {
    const [contactDetails, setContactDetails] = useState({});
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        fetchData();
      }, []);
    
    const fetchData = async () => {
        try {
          const contactDetailsData = await getContactDetails();
          setContactDetails(contactDetailsData);
          setLoading(false);
        } catch (error) {
          console.error(error);
          setLoading(false);
        }
    };

    const phoneNumber = contactDetails.phoneNumber;
    const address = contactDetails.address;
    const googleMapsUrl = `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(address)}`;
    

    // Function to initiate a phone call when clicking the phone number
    const handlePhoneClick = () => {
        window.location.href = `tel:${phoneNumber}`;
    }

    return (
        <Container fluid>
            <Header />
            <Navigation activePath="/hours_locations" />
            <HeaderMob />

            <div className="main">
                <div className="center">
                    <h1>HOURS & LOCATIONS</h1>
                    <div className="location">
                        <div className="address mr-3 mb-2">
                            <a href={googleMapsUrl}
                                target="_blank" rel="noopener noreferrer" className="link-as-text" >
                                {address}
                            </a>
                        </div>
                        <div className="phone mr-3">
                            <span onClick={handlePhoneClick}>{phoneNumber}</span>
                        </div>
                    </div>
                    <h5>Breakfast</h5>
                    <p>6:30 AM to 11:00 AM, weekdays</p>
                    <p>6:30 AM to 11:30 AM, weekends</p>
                    <h5>Lunch</h5>
                    <p>12:00 AM to 2:00 PM, daily</p>
                    <h5>Dinner</h5>
                    <p>5:30 PM to 11:00 PM, daily</p>
                    <h5>Bakery</h5>
                    <p>7:00 AM to 7:00 AM, weekdays</p>
                    <p>7:00 AM to 2:00 AM, weekends</p>
                </div>
            </div>

            <Footer />
      </Container>
    );
}

export default HoursAndLocations;
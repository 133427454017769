import React, { useState, useEffect } from "react";
import { Container, Form } from 'react-bootstrap';
import Navigation from '../components/Navigation';
import Header from '../components/Header';
import HeaderMob from "../components/HeaderMob";
import Footer from "../components/Footer";
import { getBakeryImages } from "./GetData";
import bakery from "../images/Bakery.jpg";
import ImageCarousel from "../components/ImageCarousel";
import "./custom.css";

const Bakery = () => {
    const [carouselImages, setCarouselImages] = useState([]);

    useEffect(() => {
        const fetchHomeImages = async () => {
            try {
                const images = await getBakeryImages();

                const urls = images.map(image => image.imageUrl);
                setCarouselImages(urls);
            } catch (error) {
                console.error(error);
            }
        };

        fetchHomeImages();
    }, []);

    return (
        <Container fluid>
            <Header />
            <Navigation activePath="/bakery" />
            <HeaderMob />

            <ImageCarousel images={carouselImages} />

            <div className="main">
                <div className="div-half">
                    <h2 className="h2-center">HANDCRAFTED WITH LOVE</h2>
                    <p>Our bakers are artisans, passionate about their craft. They knead, shape, and bake with love, 
                        infusing each creation with a touch of their personality. Every pastry, every loaf, 
                        is a unique masterpiece.</p>
                </div>

                <div className="row descrip des des1">
                    <div className="col-sm-12 col-md-9 order-md-2">
                        <img src={bakery} />
                    </div>
                    <div className="col-sm-12 col-md-3 order-md-1">
                        <h1><span className="h1-title">EAT PASTRY!</span></h1>
                        <p className="p-des">
                            Freshly baked croissants, muffins, and artisanal delights. Dine in or take on the go.
                            <div className="line mx-auto"></div>
                        </p>
                    </div>
                </div>
                
            </div>

            <Footer />
      </Container>
    );
}

export default Bakery;